import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nicenano"
    }}>{`nice!nano`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.14110429447852%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABUyczdwjD/8QAGxABAQABBQAAAAAAAAAAAAAAAgEDABESEzL/2gAIAQEAAQUCyum9llPnIedRt0Xsf//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwE1f//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwERf//EABkQAAMAAwAAAAAAAAAAAAAAAAABERAxcf/aAAgBAQAGPwJQXBYUmhH/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQWGB/9oACAEBAAE/IRZV8j2l2m08jCiYRvkprKQpYT//2gAMAwEAAgADAAAAEIgf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxDSEZ//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EMFSo//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQWHh/9oACAEBAAE/EKLZLSje+w/wDQo1OfkVw6hgfhRr3FtKpFOkyUAdn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "nice!nano",
          "title": "nice!nano",
          "src": "/static/aa55cda5408249fa002266d4f85e5612/6aca1/nicenano.jpg",
          "srcSet": ["/static/aa55cda5408249fa002266d4f85e5612/d2f63/nicenano.jpg 163w", "/static/aa55cda5408249fa002266d4f85e5612/c989d/nicenano.jpg 325w", "/static/aa55cda5408249fa002266d4f85e5612/6aca1/nicenano.jpg 650w", "/static/aa55cda5408249fa002266d4f85e5612/7c09c/nicenano.jpg 975w", "/static/aa55cda5408249fa002266d4f85e5612/01ab0/nicenano.jpg 1300w", "/static/aa55cda5408249fa002266d4f85e5612/e1596/nicenano.jpg 2048w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The nice!nano is a Pro Micro replacement development board offering BLE using the nRF52840 chip. It has the same pinout as the Pro Micro meaning it will work with almost any Pro Micro keyboard. The nice!nano also has a 3.7V lithium battery charger on board as well as a software level switch to cut off power to LEDs, which can eat 1mA each even when off!`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "/docs/nice-nano/faq"
      }}><strong parentName="a">{`FAQs`}</strong></a>{` if you have more questions. To get your nice!nano up and running check out `}<a parentName="p" {...{
        "href": "/docs/nice-nano/getting-started"
      }}><strong parentName="a">{`Getting Started`}</strong></a>{`.`}</p>
    <p>{`If you're a hardware designer interested to learn more about the pinout and schematic check out the `}<a parentName="p" {...{
        "href": "/docs/nice-nano/pinout-schematic"
      }}><strong parentName="a">{`Pinout and Schematic`}</strong></a>{`.`}</p>
    <h2 {...{
      "id": "recommended-batteries-and-sockets"
    }}>{`Recommended batteries and sockets`}</h2>
    <p>{`To run your nice!nano wirelessly, you need a battery. In specific, you need a 3.7v rechargeable lithium battery that is at least 100mAh large (you can probably get away with 80mAh). Note when choosing a battery, the recharge rate is 100mA, so a 2,000mAh battery will take 20 hours to charge. `}<strong parentName="p">{`The overall recommended battery for most low power (no LEDs or extra power sinks) is the 301230 battery`}</strong>{`. This battery is 3mm thick and fits underneath a socketed nice!nano very nicely. These can be found on some vendors' stores as well as on AliExpress.`}</p>
    <p>{`It's also highly recommended that you socket your nice!nano. It offers ease of access to the battery and makes debugging your keyboard so much easier. In short, it's a small investment to save you lots of time and possibly money in the future. `}<strong parentName="p">{`Standard female machine pin sockets are recommended to go along with the 301230 battery`}</strong>{`. In most cases the battery should fit underneath the socketed nice!nano. Be weary o flipped nice!nanos `}<em parentName="p">{`with`}</em>{` pins and other parts underneath (you don't want to puncture your battery!). You can find these on AliExpress all over the place.`}</p>
    <h2 {...{
      "id": "bootloader"
    }}>{`Bootloader`}</h2>
    <p>{`The nice!nano uses the `}<a parentName="p" {...{
        "href": "https://github.com/adafruit/Adafruit_nRF52_Bootloader"
      }}>{`Adafruit nRF52 Bootloader`}</a>{`. You can use the files from the release page of the repository, or you can use the v0.6.0 bootloader I have here. `}<a parentName="p" {...{
        "href": "/assets/nice_nano_bootloader-0.6.0_s140_6.1.1.hex"
      }}>{`Click here to download`}</a>{`.`}</p>
    <h2 {...{
      "id": "specifications"
    }}>{`Specifications`}</h2>
    <ul>
      <li parentName="ul">{`Mid mount USB-C port making the board a 3.2mm total thickness (thinner than a Pro Micro)`}</li>
      <li parentName="ul">{`nRF52840 chip on board with 1MB of Flash and 256KB of RAM`}</li>
      <li parentName="ul">{`Adafruit Bootloader loaded offering DFU flashing as well as flashing via UF2 storage (similar to dragging a file to a flash drive!)`}</li>
      <li parentName="ul">{`Programmable indicator (blue) LED as well as a charging indicator (orange) LED`}</li>
      <li parentName="ul">{`Battery voltage reader to report battery percentage to main device`}</li>
      <li parentName="ul">{`External power can be cut off using an on board MOSFET saving power from LEDs (each can draw 1mA when off!)`}</li>
      <li parentName="ul">{`3 extra GPIO pins offering a total of 21 GPIO pins`}</li>
      <li parentName="ul">{`3.3V out of the VCC pin to power external features`}</li>
      <li parentName="ul">{`32.768 kHz oscillator on board for real-time clock capabilities`}</li>
      <li parentName="ul">{`Clean design with matte black solder mask, immersion gold plating, and Tg 170 FR4 for durability`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      